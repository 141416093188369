import { getCookie, getCpfAndSusep, isTokenExpired, setCookie } from "."
import { api } from "../services"
import { Token } from "../types"

const auth_uri = `${process.env.AUTH_URI}/authorize`
const client_id = process.env.CLIENTID_PUBLIC
const redirect_uri = process.env.REDIRECT_URI
const env_uri = process.env.ENV_URI

const urlAuthCode = `${auth_uri}?client_id=${client_id}&idp=1&state=rAnDoMtExT&redirect_uri=${redirect_uri}`
const urlLoginPath='/portal/site/corretoronline/template.LOGIN/'
const urlHomepagePath='/corretoronline/homepage/'

// @ts-ignore
const getOAuthToken = async (formBody: string) => {
    try {
        const { data } = await api.post('/token', formBody, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })

        const token = JSON.stringify(data)
        localStorage.setItem('@__portoseguro/__token__', token);
        localStorage.setItem('token', token);

    return token
  } catch (error) {
    throw new Error(error)
  }
}

// @ts-ignore
const getRefreshToken = async (refreshToken: string): string => {
    const data = {
        grant_type: 'refresh_token',
        client_id: client_id,
        refresh_token: refreshToken,
    }
    const formBody = new URLSearchParams(data).toString()

    try {
        await getOAuthToken(formBody)
    } catch (e) {
      window.location.href = env_uri + urlLoginPath
    }
}

// @ts-ignore
const getToken = async (code: string, refresh_token: string | undefined ): string => {
    const data = {
        grant_type: 'authorization_code',
        client_id: client_id,
        code: code,
    }
    const formBody = new URLSearchParams(data).toString()
    
    try {
        await getOAuthToken(formBody)
    } catch (e) {
        refresh_token ? await getRefreshToken(refresh_token) : window.location.href = env_uri + urlLoginPath
    }
}

function roboLogin() {
    const currentUrl = window.location.href;
    const existingRedirectUrl = localStorage.getItem('REDIRECT_URL_COL');
    const hasRedirected = localStorage.getItem('HAS_REDIRECTED');

    if (existingRedirectUrl && currentUrl !== existingRedirectUrl && !hasRedirected) {
        localStorage.setItem('HAS_REDIRECTED', 'true');
        window.location.href = existingRedirectUrl;
        return;
    }
}


export const authorizationProcess = async () => {
    return new Promise(async (resolve) => {
        try {

            const isAutomateLogin = getCookie('TOKEN_ROBO')
            const hasRedirected = localStorage.getItem('HAS_REDIRECTED')
            const params = new URL(location.href).searchParams
            const code = params.get('code')
            const tokenString = localStorage.getItem('@__portoseguro/__token__')
            const invalidToken = !tokenString || tokenString === 'null' || tokenString === 'undefined'
            const invalidCode = !code || code === 'null' || code === 'undefined'
            const currentUrl = window.location.href;
            const existingRedirectUrl = localStorage.getItem('REDIRECT_URL_COL');

            if(isAutomateLogin && !existingRedirectUrl && !hasRedirected){
                localStorage.setItem('REDIRECT_URL_COL', currentUrl);
            }

            if(isAutomateLogin && existingRedirectUrl && hasRedirected){
                localStorage.removeItem('REDIRECT_URL_COL');
                localStorage.removeItem('HAS_REDIRECTED')
            }

            let token: Token
            try {
                if(tokenString){
                    token = JSON.parse(tokenString)
                }
            } catch (e) { }

            const tokenStorage = token?.access_token || null
            const refreshTokenStorage = token?.refresh_token || null
            const tokenExpired = invalidToken || isTokenExpired(tokenStorage)
            const refreshTokenExpired = invalidToken || isTokenExpired(refreshTokenStorage)
            const hasToken = tokenStorage && refreshTokenStorage
            const authtokenJwt = getCookie('AUTHTOKEN_JWT')
            const stringCpfLogado = getCpfAndSusep(authtokenJwt)

            if((!tokenExpired || !refreshTokenExpired) && existingRedirectUrl){
                localStorage.removeItem('REDIRECT_URL_COL');
            }
            
            
            if(!!getCookie('SMSESSION') && !hasToken && invalidCode) {
                if (!stringCpfLogado.cpf || !stringCpfLogado.susep || stringCpfLogado.exp) {
                    localStorage.removeItem('@__portoseguro/__token__');
                    window.location.href = env_uri + urlLoginPath
                    return
                }
                window.location.replace(urlAuthCode)
                return
            }

            if (!!authtokenJwt) {
                window.localStorage.setItem('cpfLogado', `${stringCpfLogado.cpf}_${stringCpfLogado.susep}`)
                setCookie('LGNID', stringCpfLogado.cpf)
                setCookie('LGNIDSUSEP', stringCpfLogado.susep)
            } else {
                window.location.href = env_uri + urlHomepagePath
                return
            }

            if (tokenExpired) {
                if (refreshTokenExpired) {
                    if (stringCpfLogado.exp || invalidCode) {
                        localStorage.removeItem('@__portoseguro/__token__');
                        window.location.href = env_uri + urlLoginPath
                        return
                    }
                    await getToken(code, refreshTokenStorage)
                    if(isAutomateLogin){
                        roboLogin()
                    }
                    resolve(null)
                    return
                } else {
                    await getRefreshToken(refreshTokenStorage)
                    resolve(null)
                    return
                }
            }
            resolve(null)
        } catch (e) {
          localStorage.removeItem('@__portoseguro/__token__');
          window.location.href = env_uri + urlLoginPath
        }
    })
}
