import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import "./index.css";
import "./assets/porto-fonts.css";
import { authorizationProcess } from "./utils/authorization";
import {
  DefaultLoader,
  HomeLoader,
  HeaderLoader,
  FooterLoader,
} from "./components";
import { authorizationSSO } from "./utils/authorizationSSO";

const LOGGED_URLS = [
  "/novocol/homepage",
  "/novocol/luckynumbers",
  "/novocol/brokerslink",
  "/novocol/brokersclick",
  "/novocol/calculos",
  "/novocol/financialmanagement",
  "/novocol/renegotiation",
  "/novocol/quoters",
  "/novocol/quoters-home",
  "/novocol/accessmanager",
  "/novocol/gdo",
  "/novocol/commissions",
  "/novocol/sinister",
  "/novocol/faq",
  "/novocol/produto",
  "/novocol/accesspostsales",
  "/novocol/fale-conosco",
  "/novocol/clientmanager",
  "/novocol/claims",
  "/novocol/cota-web",
];

const startMfe = async () => {
  const data = {
    props: {
      headerProps: {
        basename: "/novocol",
      },
      homeProps: {
        basename: "/novocol/homepage",
      },
      footerProps: {
        basename: "/novocol",
      },
      luckyNumbersProps: {
        basename: "/novocol/luckynumbers",
      },
      brokersLinkProps: {
        basename: "/novocol/brokerslink",
      },
      brokersClickProps: {
        basename: "/novocol/brokersClick",
      },
      calculosProps: {
        basename: "/novocol/calculos",
      },
      financialManagementProps: {
        basename: "/novocol/financialmanagement",
      },
      quotersProps: {
        basename: "/novocol/quoters",
      },
      quotersHomeProps: {
        basename: "/novocol/quoters-home",
      },
      accessManagerProps: {
        basename: "/novocol/accessmanager",
      },
      finalizeLinkRegisterProps: {
        basename: "/novocol/finalizelinkregister",
      },
      unloggedHomeProps: {
        basename: "/novocol",
      },
      renegotiationProps: {
        basename: "/novocol/renegotiation",
      },
      commissionsProps: {
        basename: "/novocol/commissions",
      },
      gdoProps: {
        basename: "/novocol/gdo",
      },
      sinisterProps: {
        basename: "/novocol/sinister",
      },
      claimsProps: {
        basename: "/novocol/claims",
      },
      faqProps: {
        basename: "/novocol/faq",
      },
      produtoProps: {
        basename: "/novocol/produto",
      },
      accessPostSalesProps: {
        basename: "/novocol/accesspostsales",
      },
      faleConoscoProps: {
        basename: "/novocol/fale-conosco",
      },
      clientManagerProps: {
        basename: "/novocol/clientmanager",
      },
      cotaWebProps: {
        basename: "/novocol/cota-web"
      },
    },
    loaders: {
      defaultLoader: DefaultLoader,
      headerLoader: HeaderLoader,
      homeLoader: HomeLoader,
      footerLoader: FooterLoader,
    },
  };
  const routes = constructRoutes(microfrontendLayout, data);
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });
  applications.forEach(registerApplication);
  layoutEngine.activate();
  start();
};

// @ts-ignore
await new Promise(async (resolve) => {
  const currentUrl = window.location.pathname;
  const queryString = window.location.search;
  if (
    LOGGED_URLS.some((url) => currentUrl.toLocaleLowerCase().startsWith(url))
  ) {
    if (queryString.includes("smsession")) {
      await authorizationSSO();
    } else {
      await authorizationProcess();
    }
  }

  resolve(null);
}).then(async () => {
  startMfe();
});
