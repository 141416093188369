import "./FooterLoader.css"
import LoaderWhiteSvg from "../../../assets/loaders/loader-white.svg";

export const FooterLoader = `
<footer class="container-footer">
  <div class="loaderSvg">
    <img src="${LoaderWhiteSvg}" alt="Carregando"/>
  </div>
</footer>
`;
