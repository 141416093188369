export function isTokenExpired(token: string | null) {
  if (!token) return true

  const base64Url = token.split('.')[1]
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')
  const payload = !!base64 && JSON.parse(window.atob(base64))
  const currentTime = Math.floor(Date.now() / 1000)
  return (payload?.exp || 0) < currentTime
}

export function getCpfAndSusep(token: string | null | undefined) {
  if (!token) return {
      cpf: undefined,
      susep: undefined,
      exp: undefined
  }

  const base64Url = token.split('.')[1]
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')
  const payload = !!base64 && JSON.parse(window.atob(base64))
  
  if (payload?.cn && payload?.lgiauxcod) {
    const currentTime = Math.floor(Date.now() / 1000)
    const isEpired = () => {
      const difSeconds = Math.abs(currentTime - payload?.exp || 0)
      const difHours = difSeconds / 3600
      return difHours >= 2
    } 

    return {
      cpf: payload?.cn as string,
      susep: payload?.lgiauxcod as string,
      exp: isEpired()
    }
  }

  return {
    cpf: undefined,
    susep: undefined,
    exp: undefined
  }
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts && parts.length === 2) return parts.pop()?.split(';').shift()
  return
}

export function setCookie(name: string, value: string) {
  const originalCookies = `; ${document.cookie}`
  const newCookies = originalCookies.concat(`; ${name}=${value}`)
  document.cookie = newCookies
}
