const env_uri = process.env.ENV_URI

const urlLoginPath = '/portal/site/corretoronline/template.LOGIN/'

export function authorizationSSO() {
    return new Promise(async (resolve) => {
        try {
            const queryString = window.location.search;
            const paramsURL = new URLSearchParams(queryString);
            let LGNIDUser = '';
            let jwtMuleValue = '';

            const setCookie = (key: string, value: string) => {
                document.cookie = `${encodeURIComponent(key).toUpperCase()}=${decodeURIComponent(value)}; domain=${process.env.DOMAIN_COOKIE_SSO}; path=/`;
            };

            paramsURL.forEach((value, key) => {
                if (key === "LGNID") {
                    LGNIDUser = value;
                } else if (key === "JWT_MULE") {
                    jwtMuleValue = value;
                } else if (!["LGNID", "LGNIDSUSEP", "SUSEP", "AUTHTOKEN", "AUTHTOKEN_JWT", "LEGACYSESSION"].includes(key)) {
                    setCookie(key, value);
                }
            });

            if (jwtMuleValue) {
                const decodedString = atob(jwtMuleValue);
                const tokenParsed = JSON.parse(decodedString);
                localStorage.setItem('@__portoseguro/__token__', decodedString);
                localStorage.setItem('token', decodedString);

                try {
                    const response = await fetch(`https://apis-hml.portoseguro.brasil/corretor-online/home/v2/get-user-authorization?userName=${LGNIDUser}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${tokenParsed.access_token}`
                        }
                    });

                    if (!response.ok) {
                        throw new Error('Erro ao buscar autorização do usuário');
                    }

                    const data = await response.json();
                    setCookie('LGNID', data.userName);
                    setCookie('LGNIDSUSEP', `${data.userName}_${data.selectedSusep}`);
                    setCookie('SUSEP', data.selectedSusep);
                    setCookie('AUTHTOKEN', data.legacyToken.AUTHTOKEN);
                    setCookie('AUTHTOKEN_JWT', data.legacyToken.AUTHTOKEN_JWT);
                    setCookie('LEGACYSESSION', data.legacyToken.LEGACYSESSION);
                } catch (e) {
                    console.error('Erro de autenticação:', e);
                }
            }

            resolve(null);
        } catch (e) {
            console.error('Erro de autenticação:', e);
            localStorage.removeItem('@__portoseguro/__token__');
            window.location.href = `${env_uri}${urlLoginPath}`;
        }
    })
}