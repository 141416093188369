import "./HomeLoader.css"
import LoaderBlackSvg from "../../../assets/loaders/loader-black.svg";

export const HomeLoader = `
    <section class="container-home">
      <div class="loaderSvg">
        <img class="svg" src="${LoaderBlackSvg}" alt="Carregando"/>
      </div>
    </section>
  `;
