import "./HeaderLoader.css"
import PortoLogoSvg from "../../../assets/logo/logo_col.svg";

export const HeaderLoader = `
<header class="header-main-container">
  <div class="container">
    <div class="content">
        <div class="header-content_left">  
          <img src="${PortoLogoSvg}" class="header-content_left--logo" width="180px" alt="Corretor Online - Porto" />
        </div>
    </div>
  </div>
</header>
`;
